import React, { lazy, useEffect, useState } from "react";

const LatestVideos = lazy(() => import("./LatestVideos"));
const LatestPodcasts = lazy(() => import("./LatestPodcasts"));
const ShareModal = lazy(() => import("../ShareModal"));

export const Media = () => {
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const openShareModal = () => setIsShareModalOpen(true);
  const closeShareModal = () => setIsShareModalOpen(false);

  useEffect(() => {
    const updatePageTitle = () => {
      document.title = "Media | Cricket8";
    };
    updatePageTitle();
    return () => {};
  }, []);

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] content-t">
      <div className="flex flex-row justify-between items-center h-[80px] w-full bg-linear-header-media p-5">
        <div className="flex items-center">
          <h1 className="text-[30px] md:text-[50px]">MEDIA</h1>
        </div>
        <div className="flex flex-row items-center">
          <p className="font-chakra text-[15px] normal-case">
            Cricket8 video & podcasts.
          </p>
          <button
            className="w-[30px] h-[30px] flex justify-center items-center ml-[10px]"
            style={{ border: "1px solid #D2FF00" }}
            onClick={openShareModal}
          >
            <img src="../img/public/Union.png" />
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <div className="flex flex-col w-full min-h-[520px] bg-header p-4">
          <div className="my-4 w-full text-start font-anton text-[30px] text-white">
            LATEST VIDEOS
          </div>
          <LatestVideos maxVids={4} style="summary" />
          <a href="/videos" className="mt-auto w-full flex justify-center">
            <div className="mt-4 bg-lime h-[50px] w-[100%] max-w-[430px] text-main flex justify-center items-center">
              ALL VIDEOS
            </div>
          </a>
        </div>

        <div className="flex flex-col w-full min-h-[500px] bg-header p-4">
          <div className="my-4 w-full text-start font-anton text-[30px] text-white">
            LATEST PODCASTS
          </div>
          <LatestPodcasts maxPods={4} style="summary" />
          <a href="/podcasts" className="mt-4 w-full flex justify-center">
            <div className="bg-lime h-[50px] w-[100%] max-w-[430px] text-main flex justify-center items-center">
              ALL PODCASTS
            </div>
          </a>
        </div>
      </div>
      <ShareModal
        isOpen={isShareModalOpen}
        onRequestClose={closeShareModal}
        url={window.location.href}
      />
    </div>
  );
};
export default Media;
